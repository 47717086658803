import Vue from "vue";
import { i18n } from "@storefront/core/i18n";
//import { Logger } from "@storefront/core/lib/logger";
export default Vue.extend({
  data() {
    return {
      username: "",
      password: "",
      loginData: { email: "", password: "" },
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    /**
     * login as user
     */
    async login() {
      let valid = true;
      let loginOk = false;
      //check username
      if (this.loginData.email == "") {
        const msg = {
          type: "danger",
          title: i18n.t("login_error"),
          text: i18n.t("email_is_required_field"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        valid = false;
      } else if (!this.emailReg.test(this.loginData.email)) {
        const msg = {
          type: "danger",
          title: i18n.t("login_error"),
          text: i18n.t("email_is_invalid_field"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        valid = false;
      }
      if (this.loginData.password == "") {
        const msg = {
          type: "danger",
          title: i18n.t("login_error"),
          text: i18n.t("password_is_required_field"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        valid = false;
      }
      if (valid == true) {
        loginOk = await this.$store.dispatch("user/login", {
          username: this.loginData.email,
          password: this.loginData.password,
        });
        if (loginOk == true) {
          const msg = {
            type: "success",
            title: i18n.t("login_success"),
            text: i18n.t("you_are_logged_in"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          //this.$router.push({ name: "account" });
        }
      }
    },
  },
});
