<template>
  <BaseLayout v-slot="{}">
    <Breadcrumbs />
    <section class="login">
      <b-container>
        <b-row>
          <b-col
            offset-lg="1"
            offset-xl="2"
            lg="10"
            xl="8"
            class="position-relative"
          >
            <span class="login-title text-white">{{ $t("login") }}</span>
            <div class="login-component">
              <div
                class="d-flex flex-column flex-md-row justify-content-between"
              >
                <div class="info login--inner-component pb-25 pb-md-0">
                  <span class="title d-block">{{ $t("new_customers") }}</span>
                  <p class="mb-15 mb-md-45">
                    {{ $t("register_info") }}
                  </p>
                  <div class="text-right py-4">
                    <b-button
                      @click="$router.push({ name: 'create-account' })"
                      variant="info"
                      class="text-white py-3 px-15"
                      >{{ $t("create_account") }}</b-button
                    >
                  </div>
                </div>
                <div class="form login--inner-component pt-25 pt-md-0">
                  <span class="title d-block">{{
                    $t("registered_customers")
                  }}</span>
                  <span class="form-description d-block mb-30">{{
                    $t("account_login")
                  }}</span>
                  <b-form @submit.prevent="onLoginFormSubmit" novalidate>
                    <b-form-group
                      id="email-group"
                      :label="$t('req_email')"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="loginData.email"
                        :state="loginValidation.emailState"
                        @blur="validateEmail"
                        type="email"
                        required=""
                        aria-describedby="email-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="email-feedback">
                        {{ loginValidation.emailError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      id="password-group"
                      :label="$t('req_password')"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="loginData.password"
                        type="password"
                        required=""
                        :state="loginValidation.passwordState"
                        @blur="validatePassword"
                        aria-describedby="password-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="password-feedback">
                        {{ loginValidation.passwordError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <span class="form-info mb-15 d-block">{{
                      $t("required_fields")
                    }}</span>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-link
                        to="/forgot-password"
                        class="font-weight-normal link-underline"
                        >{{ $t("password_forgot") }}</b-link
                      >
                      <b-button
                        type="submit"
                        variant="info"
                        class="text-white ml-20 form-submit-btn"
                        >{{ $t("login") }}</b-button
                      >
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/base/core/components/common/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import Login from "@storefront/core/modules/user/mixins/login";

export default {
  name: "Login",
  mixins: [Login],
  components: {
    BaseLayout,
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("login") },
        { name: "keywords", content: this.$t("login") },
        { name: "description", content: this.$t("login") },
      ],
      title: this.$t("login"),
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("login"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loginValidation: {
        emailState: null,
        emailError: "",
        passwordState: null,
        passwordError: "",
      },
    };
  },
  methods: {
    onLoginFormSubmit() {
      let formValid = true;
      if (this.loginValidation.emailState != true) {
        this.validateEmail();
        if (this.loginValidation.emailState != true) {
          formValid = false;
        }
      }

      if (this.loginValidation.passwordState != true) {
        this.validatePassword();
        if (this.loginValidation.passwordState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        this.login();
      }
    },
    validateEmail() {
      if (this.loginData.email.length == 0) {
        this.loginValidation.emailState = false;
        this.loginValidation.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.loginData.email)) {
        this.loginValidation.emailState = false;
        this.loginValidation.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.loginValidation.emailState = true;
      return true;
    },

    validatePassword() {
      if (this.loginData.password.length == 0) {
        this.loginValidation.passwordState = false;
        this.loginValidation.passwordError = this.$t(
          "password_is_required_field"
        );
        return false;
      }
      this.loginValidation.passwordState = true;
      return true;
    },
  },
};
</script>
